<template>
    <div>
        <div v-if="!isLargeData || !dataClamped" v-html="columnData"/>

        <template v-else>
            <v-clamp autoresize :max-lines="6"> {{strippedData}}
                <template #after>
                    <a class="link-normal" @click="() => {dataClamped = false}"> more</a>
                </template>
            </v-clamp>
        </template>

        <a v-if="!dataClamped" class="link-normal" @click="() => {dataClamped = true}"> less</a>
    </div>
</template>

<script>
import VClamp from "vue-clamp";

export default {
    components: {VClamp},

    props: {
        columnData: String,
    },

    data() {
        return {
            dataClamped: true,
        }
    },

    computed: {
        isLargeData() {
            return this.strippedData.length > 100;
        },

        strippedData() {
            return this.columnData.replace(/(<([^>]+)>)/gi, "");
        }
    }
}
</script>

<style scoped>

</style>
